import { Link } from "react-router-dom";
import { ReactComponent as IconDislike } from "./icon_dislike.svg";
import { ReactComponent as IconHipnosis } from "./icon_hipnosis.svg";
import MarcusAraujo from "./MarcusAraujo.png";
import styles from "./NegativeImpacts.module.scss";
import { ReactComponent as IconHipnosis2 } from "./icon_hipnosis2.svg";

const problemList = [
  "Ansiedade e estresse crônico",
  "Autoestima baixa e falta de confiança",
  "Hábitos e vícios indesejados, como tabagismo e compulsão alimentar",
  "Dores físicas e desconfortos crônicos sem explicação médica",
  "Falta de motivação e baixa produtividade",
  "Insônia ",
  "Dificuldade em controlar emoções e reações, como raiva e medo excessivos",
  "Problemas de saúde mental, como depressão e ansiedade generalizada",
  "Dificuldades em perder peso e manter uma alimentação saudável",
  "Dificuldades em superar traumas e fobias",
];

export const NegativeImpacts = () => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>O peso da dor não tratada</h2>
          <p style={{ maxWidth: 800 }}>
            Conheci várias{" "}
            <span>pessoas que não conseguiam superar seus problemas</span> e
            acabaram se tornando dependentes de remédios, problemas como:
          </p>
        </div>

        <div className={styles.containerText}>
          <div>
            <img src={MarcusAraujo} alt="Marcus Araujo" />
          </div>
          <div>
            <ul>
              {problemList.map((problem, index) => (
                <li key={index}>
                  <div>
                    <IconDislike width={24} height={24} />
                  </div>
                  {problem}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="cta_block">
          <div
            className="text_wrapper"
            style={{
              flexDirection: "row",
              maxWidth: 600,
              gap: 32,
              textAlign: "left",
              alignItems: "flex-start",
            }}
          >
            <div>
              <IconHipnosis2 />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <p className="headline">
                Não deixe que esses problemas limitem sua vida
              </p>
              <p className="subtitle">
                Descubra como superá-los com a Terapia Breve Com Hipnose e
                transforme sua vida em poucas sessões!
              </p>
            </div>
          </div>
          <Link to="https://api.whatsapp.com/send?phone=5511953639225&text=Ol%C3%A1,%20vim%20pelo%20TBCH,%20gostaria%20de%20agendar%20minha%20terapia%20com%20hipnose." className="button">
            <IconHipnosis />
            <span>Quero me tratar!</span>
          </Link>
        </div>
      </div>
    </section>
  );
};
