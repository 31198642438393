import { Link } from "react-router-dom";
import styles from "./HowItWorks.module.scss";
import { ReactComponent as IconHipnosis } from "./icon_hipnosis.svg";

const list = [
  {
    title: "Anamnese",
    description:
      "Neste momento, vamos conversar sobre suas necessidades e expectativas em relação à TBCH, incluindo suas dores, angústias e medos. Essa etapa é fundamental para personalizar o tratamento e garantir que atenda suas necessidades específicas.",
  },
  {
    title: "Pré-talk",
    description:
      "Antes de iniciarmos a sessão de TBCH, vou explicar como ela funciona e tirar todas as suas dúvidas. Meu objetivo é certificá-lo de que a hipnoterapia é o melhor tratamento para você e garantir que você esteja confortável e seguro durante a sessão.",
  },
  {
    title: "Indução",
    description:
      "É aqui que iniciamos a sessão. Utilizando técnicas específicas e comunicação, vou guiá-lo em direção ao estado de transe e focar sua atenção em um ponto específico que precisamos tratar.",
  },
  {
    title: "A terapia",
    description:
      "Durante a sessão de TBCH, vamos trabalhar para re-significar experiências, transformar emoções negativas em positivas e trazer novas percepções. O objetivo é aumentar os aprendizados e ajudá-lo a alcançar uma vida mais saudável e equilibrada.",
  },
  {
    title: "Potencialização",
    description:
      "Após ressignificar e trabalhar nas queixas iniciais, é hora de potencializar os resultados. Vamos direcionar o foco para sugestões positivas de acordo com seus objetivos, com o objetivo de maximizar os efeitos da TBCH e ajudá-lo a alcançar uma vida mais plena e satisfatória.",
  },
  {
    title: "Emersão",
    description:
      "Esta é a fase final da sessão, onde vou direcionar gradativamente sua atenção de volta para o momento presente. O objetivo é garantir que você se sinta confortável e seguro ao retornar ao seu estado consciente.",
  },
];

export const HowItWorks = () => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>Como funciona a sessão de TBCH?</h2>
          <p>
            Há inúmeras curiosidades sobre a hipnose, e como{" "}
            <span>essa ferramenta pode auxiliar na vida de uma pessoa!</span>{" "}
            Aqui vou estruturar de maneira breve como funciona uma sessão de
            TBCH no consultório.
          </p>
        </div>
        <div className={styles.cardList}>
          {list.map((item, index) => (
            <div className={styles.card} key={index}>
              <div className={styles.number}>{index + 1}</div>
              <div>
                <h3 className={styles.title}>{item.title}</h3>
                <p className={styles.description}>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="cta_block">
          <div className="text_wrapper">
            <p className="headline" style={{maxWidth: 700}}>
              Transforme sua vida com a TBCH e alcance a felicidade e plenitude
              que você merece.
            </p>
            <p className="subtitle" style={{maxWidth: 600}}>
              Descubra como podemos ajudá-lo a superar seus desafios e alcançar
              seus objetivos.
            </p>
          </div>
          <Link to="https://api.whatsapp.com/send?phone=5511953639225&text=Ol%C3%A1,%20vim%20pelo%20TBCH,%20gostaria%20de%20agendar%20minha%20terapia%20com%20hipnose." className="button">
            <IconHipnosis />
            <span>Quero me tratar!</span>
          </Link>
        </div>
      </div>
    </section>
  );
};
