import styles from "./Form.module.scss";
import MarcusAraujo from "./MarcusAraujo.png";

export const Form = () => {
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <img src={MarcusAraujo} alt="Marcus Araujo" />
        <div className={styles.textWrapper}>
          <h1>TRATAMENTOS COM HIPNOTERAPIA!</h1>
          <p>
            Descubra como a TBCH pode ajudar a transformar sua vida.
            <br />
            <span>Agende sua sessão de avaliação!</span>
          </p>
        </div>
      </header>
      <iframe src="https://n4kful1zj41.typeform.com/to/DnhjE4h4" />
    </div>
  );
};
