import styles from "./FAQ.module.scss";
import { useState } from "react";
import ArrowDown from "./down-arrow.png";

const FAQList = [
  {
    title: "O que é a TBCH?",
    description:
      "A Terapia Breve Com Hipnose é uma forma de terapia que utiliza a hipnose para ajudar as pessoas a alcançar seus objetivos em um período relativamente curto de tempo. O objetivo é ajudar os clientes a superar desafios emocionais, comportamentais e físicos em um número limitado de sessões.",
  },
  {
    title: "Como a TBCH funciona?",
    description:
      "Durante a Terapia Breve Com Hipnose, o terapeuta utiliza técnicas para ajudar o cliente a acessar sua mente subconsciente e trabalhar em questões específicas. O terapeuta trabalha em conjunto com o cliente para estabelecer objetivos",
  },
  {
    title: "Qual é a duração média da TBCH?",
    description:
      "A duração média da Terapia Breve Com Hipnose varia de acordo com as necessidades individuais do cliente e a complexidade das questões a serem tratadas. Geralmente, a TBCH é mais curta do que outras formas de terapia, com a maioria das pessoas relatando resultados positivos em poucas sessões.",
  },
  {
    title: "A TBCH é segura?",
    description:
      "Sim, a Terapia Breve Com Hipnose é considerada uma forma segura e eficaz de terapia quando realizada por um profissional qualificado e experiente. O terapeuta trabalha em conjunto com o cliente para garantir que ele esteja confortável e seguro durante a sessão.",
  },
  {
    title: "Quais são os benefícios da TBCH?",
    description:
      "Os benefícios da Terapia Breve Com Hipnose incluem alívio do estresse e ansiedade, aumento da autoconfiança, redução de hábitos indesejados, controle de dores físicas, melhoria do sono e controle de emoções e reações.",
  },
  {
    title: "Qual é a diferença entre a TBCH e outras formas de terapia?",
    description:
      "A principal diferença entre a Terapia Breve Com Hipnose e outras formas de terapia é que a hipnoterapia é mais focada e direcionada para objetivos específicos. Além disso, a hipnoterapia é geralmente mais curta do que outras formas de terapia e pode produzir resultados mais rápidos.",
  },
  {
    title: "Quem pode se beneficiar da TBCH?",
    description:
      "A Terapia Breve Com Hipnose pode beneficiar pessoas que desejam superar desafios emocionais, comportamentais e físicos, incluindo ansiedade, depressão, fobias, traumas, dores físicas, problemas de sono, entre outros. No entanto, é importante lembrar que os resultados variam de pessoa para pessoa e que a hipnoterapia deve ser sempre realizada por um profissional qualificado e experiente.",
  },
];

export const FAQ = () => {
  const FaqItem = ({ item }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className={styles.card} onClick={() => setIsOpen(!isOpen)}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: " 100%",
          }}
        >
          <h3 className={styles.title}>{item.title}</h3>
          <img src={ArrowDown} alt="" width={24} height={24} />
        </div>
        <p
          className={styles.description}
          style={{ display: isOpen ? "block" : "none" }}
        >
          {item.description}
        </p>
      </div>
    );
  };

  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>Perguntas frequentes</h2>
          <p>Tire suas dúvidas sobre a TBCH, benefícios e segurança.</p>
        </div>
        <div className={styles.cardList}>
          {FAQList.map((item, index) => (
            <FaqItem key={index} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};
