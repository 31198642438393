import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Form } from "../pages/form/Form";
import { Home } from "../pages/home/Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "https://api.whatsapp.com/send?phone=5511953639225&text=Ol%C3%A1,%20vim%20pelo%20TBCH,%20gostaria%20de%20agendar%20minha%20terapia%20com%20hipnose.",
    element: <Form />,
  },
]);

export const Routes = () => {
  return <RouterProvider router={router} />;
};
