import { ReactComponent as IconLike } from "./icon_like.svg";
import { ReactComponent as IconHipnosis } from "./icon_hipnosis.svg";
import { ReactComponent as IconHipnosis2 } from "./icon_hipnosis2.svg";
import ClienteFeliz from "./cliente_feliz.png";
import styles from "./PositiveImpacts.module.scss";
import { Link } from "react-router-dom";

const positiveImpacts = [
  "Alívio do estresse e ansiedade",
  "Melhora da autoconfiança e autoestima",
  "Redução de hábitos e vícios indesejados, como fumar e comer compulsivamente",
  "Controle de dores físicas e desconfortos, como enxaquecas e dores crônicas",
  "Aumento da motivação e produtividade",
  "Melhoria do sono e descanso mais profundo",
  "Controle de emoções e reações, como medo e raiva excessivos",
  "Melhoria da saúde mental e bem-estar geral",
  "Ajuda no controle de peso e alimentação saudável",
  "Auxílio no tratamento de traumas e fobias",
];

export const PositiveImpacts = () => {
  return (
    <section id="positive-impacts" className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>
            Descubra os incríveis benefícios da TBCH para uma vida mais saudável
            e equilibrada!
          </h2>
          <p>
            Reduza o estresse, melhore a autoestima,{" "}
            <span>controle hábitos indesejados e alcance seus objetivos</span>{" "}
            com a ajuda da TBCH,{" "}
            <span>uma forma segura e eficaz de terapia</span>.
          </p>
        </div>
        <div className={styles.containerText}>
          <div>
            <img src={ClienteFeliz} alt="Marcus Araujo" />
          </div>
          <div>
            <ul>
              {positiveImpacts.map((problem, index) => (
                <li key={index}>
                  <div>
                    <IconLike width={24} height={24} />
                  </div>
                  {problem}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="cta_block">
          <div
            className="text_wrapper"
            style={{
              flexDirection: "row",
              maxWidth: 600,
              gap: 24,
              textAlign: "left",
            }}
          >
            <div>
              <IconHipnosis2 />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
              <p className="headline">
                Supere seus desafios emocionais e transforme sua vida com a TBCH
              </p>
              <p className="subtitle">
                A Terapia Breve Com Hipnose pode transformar sua vida em poucas
                sessões!
              </p>
            </div>
          </div>
          <Link to="https://api.whatsapp.com/send?phone=5511953639225&text=Ol%C3%A1,%20vim%20pelo%20TBCH,%20gostaria%20de%20agendar%20minha%20terapia%20com%20hipnose." className="button button-pink">
            <IconHipnosis />
            <span>Quero me tratar!</span>
          </Link>
        </div>
      </div>
    </section>
  );
};
