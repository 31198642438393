import styles from "./Testmonials.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar, A11y } from "swiper";
import { Images } from "./images";

export const Testmonials = () => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>
            Alcançar a liberdade pode ser muito mais fácil do que imagina!
          </h2>
          <p>
            Veja como a TBCH pode ajudá-lo a{" "}
            <span>libertar-se de hábitos indesejados</span>, fobias e traumas, e
            alcançar a liberdade que você merece.
          </p>
        </div>
        <div className={styles.containerSwipper}>
          <Swiper
            modules={[Navigation, Scrollbar, A11y]}
            spaceBetween={16}
            slidesPerView="auto"
            navigation
            scrollbar={{ draggable: true }}
          >
            {Images.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt="" />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
