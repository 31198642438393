import { AboutMe } from "./about-me/AboutMe";
import { FAQ } from "./faq/FAQ";
import { Hero } from "./hero/Hero";
import styles from './Home.module.scss';
import { HowItWorks } from "./how-it-works/HowItWorks";
import { NegativeImpacts } from "./negative-impacts/NegativeImpacts";
import { PositiveImpacts } from "./positive-impacts/PositiveImpacts";
import { Testmonials } from "./testmonials/Testmonials";

export const Home = () => {
  return (
    <>
      <Hero />
      <NegativeImpacts />
      <Testmonials />
      <PositiveImpacts />
      <HowItWorks />
      <FAQ />
      <AboutMe />
      <footer className={styles.footer}>© TBCH - Por Marcus Araújo Hipnoterapeuta</footer>
    </>
  );
};
