import img1 from "./testmonials/img1.jpeg";
import img2 from "./testmonials/img2.jpeg";
import img3 from "./testmonials/img3.jpeg";
import img4 from "./testmonials/img4.jpeg";
import img5 from "./testmonials/img5.jpeg";
import img6 from "./testmonials/img6.jpeg";
import img7 from "./testmonials/img7.jpeg";
import img8 from "./testmonials/img8.jpeg";
import img9 from "./testmonials/img9.jpeg";
import img11 from "./testmonials/img11.jpeg";
import img12 from "./testmonials/img12.jpeg";
import img13 from "./testmonials/img13.jpeg";
import img14 from "./testmonials/img14.jpeg";
import img15 from "./testmonials/img15.jpeg";
import img16 from "./testmonials/img16.jpeg";
import img17 from "./testmonials/img17.jpeg";
import img18 from "./testmonials/img18.jpeg";
import img19 from "./testmonials/img19.jpeg";
import img20 from "./testmonials/img20.jpeg";
import img21 from "./testmonials/img21.jpeg";
import img22 from "./testmonials/img22.jpeg";
import img23 from "./testmonials/img23.jpeg";
import img24 from "./testmonials/img24.jpeg";
import img25 from "./testmonials/img25.jpeg";
import img26 from "./testmonials/img26.jpeg";
import img27 from "./testmonials/img27.jpeg";
import img28 from "./testmonials/img28.jpeg";
import img29 from "./testmonials/img29.jpeg";

export const Images = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
  img25,
  img26,
  img27,
  img28,
  img29,
];
