import styles from "./AboutMe.module.scss";
import MarcusAraujo from "./MarcusAraujo.png";

export const AboutMe = () => {
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <div className={styles.headLineWrapper}>
          <h2>Quem é Marcus Araujo?</h2>
        </div>
        <img src={MarcusAraujo} alt="Marcus Araujo" />
        <p>
          Última quinzena de 2020, um ano repleto de medos, incertezas,
          angústias e sofrimentos pelo mundo! Mas sem dúvida também um ano de
          muita expansão de consciência de boa parte da humanidade.
        </p>
        <p>
          Algo que ficou ainda mais evidente nesse ano foi a relação com a
          espiritualidade, a existência de uma força superior interna ou
          externa, a importância da transcendência dos limites do consciente em
          busca de respostas, independente das suas crenças sociais, culturais e
          religiosas...
        </p>
        <p>
          E isso é uma das coisas que mais me dá prazer na vida, conduzir
          pessoas a momentos de conexão entre corpo, mente, e espírito... é
          levar o indivíduo a um relaxamento profundo através da comunicação,
          mostrar como podemos através da nossa mente controlar até mesmo as
          sensações físicas do nosso corpo!
        </p>
        <p>
          Trazê-la para o momento presente e levá-la ao encontro de momentos
          futuros, Ao encontro da sua melhor versão! Acreditar que existe uma
          força superior, algo que nos move e que faz com que criamos a nossa
          realidade!
        </p>
        <a
          href="https://www.instagram.com/marcus_araujo_trainer/"
          className="button button-small"
          style={{ maxWidth: 400 }}
        >
          Acompanhe o conteúdo que compartilho no meu Instagram
        </a>
      </div>
    </section>
  );
};
