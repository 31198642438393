import styles from "./Hero.module.scss";
import { ReactComponent as Logo } from "../../../assets/img/logo.svg";
import { ReactComponent as IconHand } from "../../../assets/img/icon_hand.svg";
import { Link } from "react-router-dom";

export const Hero = () => {
  const scrollToElement = () => {
    window.scrollTo({top: document.querySelector("#positive-impacts").offsetTop, behavior: 'smooth'});
  }
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <Logo className={styles.logo} />
        <h1>Tratamentos com hipnoterapia</h1>
        <ul>
          <li>Traumas</li>
          <li>Ansiedade</li>
          <li>Obesidade</li>
          <li>Fibromialgia</li>
          <li>Drogas</li>
          <li>MEDOS</li>
          <li>TRANSTORNOS FÍSICOS E EMOCIONAIS</li>
          <li>DORES CRÔNICAS</li>
          <li>Tabagismo</li>
          <li>Depressão</li>
          <li>Fobias</li>
          <li>Alcoolismo</li>
          <li>Jogos</li>
        </ul>
        <button onClick={scrollToElement} className="button">
          <IconHand />
          <span>Quero saber mais!</span>
        </button>
      </div>
    </section>
  );
};
